.feed-page {
  padding: 20px;
}

.title {
  font-size: larger;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
