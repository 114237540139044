.parallax {
    /* The image used */
    background-image: url("../img/footer-background-img.jpg");
    
    /* Set a specific height */
    height: 575px;

    /* Create the parallax scrolling effect */
    background-attachment: local;
    background-position:center center;
    background-repeat: no-repeat;
    background-size: cover;

    transition: opacity .2s;
}

.fade-container {
    background-color: black;
    position: relative;
    width: 100%;
}

.middle {
    transition: .2s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.fade-container:hover .middle {
    opacity: 1;
}

.fade-container:hover .parallax {
    opacity: 0.25;
}