.align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important;
  }
  .d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
  }

  img {
    max-width: 100%;
    max-height: auto;
}


.block {
    display: block;
    width: 100%;
    border: none;
    background-color: #4CAF50;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
  }

  .zoom {
    padding: 20px;
    transition: transform .2s; /* Animation */
    margin: 0 auto;
  }
  
  .zoom:hover {
    transform: scale(1.25); /* 125% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .center {
    display: flex;
    align-items: center;
  }

  .card-title {
    font-size: x-large;
  }

  .img-thumbnail {
    border: 70;
    }